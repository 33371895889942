.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $main-color;
    height: 300px;
    width: 100%;
    z-index: 2;
    margin-top: 120px;
    position: relative;

    @include small-screen {
        height: 600px;
        display: block;
    }

    &__contact-icon {
        width: 18px;
        margin-right: 6px;

        @include small-screen {
            width: 12px;
        }

    }

    &__left-box,
    .footer__right-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: last baseline;
        padding-bottom: 60px;

        & a {
            font-size: 16px;

            @include small-screen {
                font-size: 14px;
            }
        }
    }

    &__left-box {
        padding-left: 28px;

        @include small-screen {
            padding: 0;
            padding-top: 400px;

        }

    }

    &__right-box {
        padding-right: 28px;

        @include small-screen {
            padding: 0;
            margin-top: -55px;

        }

    }

    &__center-box {
        @include vertical-flex;
        padding-top: 40px;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);

        @include small-screen {
            text-align: center;
            width: auto;
        }

    }

    &__door-icon {
        align-self: center;
    }


    &__icons-container {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-self: center;
        width: 100px;

        &__icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
            transition: all 0.1s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &__list {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        padding: 8px;
        margin-top: 10px;
        @include vertical-flex;
        font-family: $text-font, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 14px;

        @include small-screen {
            font-size: 12px;
        }

    }


}