.localisation-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
    animation: fade-in-down 0.4s ease-in forwards;

}

.localisation {

    &__map {
        margin-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    &__title {
        color: $main-color;
        font-family: title-font, Arial, Helvetica, sans-serif;
        font-size: 56px;

        @include small-screen {
            font-size: 24px;
        }
    }

    &__text {
        margin-top: 20px;
        max-width: 1100px;
    }

    &__address {
        margin-top: 40px;
        color: $main-color;
        font-size: 24px;
        border-bottom: 2px solid $main-color;

        @include small-screen {
            font-size: 18px;
        }
    }

    &__gmap-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        width: 620px;
        height: 470px;
        background: $main-color;

        @include small-screen {
            width: 100%;
        }
    }

    &__gmap {
        width: 600;
        height: 450;

        @include small-screen {
            width: 90%;
        }
    }
}