.home-main {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel {
    border: 1px solid $main-color;
    position: relative;
    width: 70%;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
    max-width: 1100px;
    box-shadow: 2px 10px 10px 0px black;

    @include small-screen {
        height: 300px;
        width: 100%;
        border-radius: 0;
        border: 0;
        border-bottom: 0px solid black;
        box-shadow: 0px 0px 0px 0px;
    }

    &__img {
        padding: 2px 4px;
        position: relative;
        background: $main-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2px;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        object-position: 50% 20%;

        @include small-screen {
            width: 100%;
            border-radius: 0;
            border: 0;

        }
    }

}

.left-chevron,
.right-chevron {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 45%;
    transform: translate(-50%);
    z-index: 1;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.left-chevron {
    left: 40px;

    @include small-screen {
        left: 30px;
    }

    &-img {
        width: 30px;
        height: 50px;

    }
}

.right-chevron {
    right: 10px;

    @include small-screen {
        right: 0px;
    }

    &-img {
        width: 30px;
        height: 50px;
        transform: rotate(180deg);
    }
}

.testing {
    display: flex;
    transition: transform 0.5s ease-in-out;
}