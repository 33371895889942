.contact-page {
    display: flex;
    flex-direction: column;
}

.contact-main {
    @include vertical-flex;
    padding-top: 200px;
    animation: fade-in-down 0.4s ease-in forwards;
}

.contact-form {
    @include vertical-flex;
    border: 2px solid $main-color;
    justify-self: center;
    align-self: center;
    margin-top: 100px;
    padding: 30px;
    min-width: 600px;
    width: 50%;
    color: $main-color;

    @include small-screen {
        min-width: 0;
        width: 80%;
        margin-top: 60px;
    }

    &-title {
        color: $main-color;
        font-family: title-font, Arial, Helvetica, sans-serif;
        font-size: 56px;
        position: absolute;
        top: 200px;

        @include small-screen {
            font-size: 26px;
        }
    }

    &__foot {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include small-screen {
            margin-top: 24px;
        }

        &__right-box a,
        .contact-form__foot__left-box a {
            color: black;

            @include small-screen {
                font-size: 14px;
            }
        }
    }


    &__container {
        @include vertical-flex;
        width: 100%;

        @include small-screen {
            width: 95%;
        }
    }

    &__message {
        @include vertical-flex;
        width: 80%;


        &__input {
            border: 2px solid black;
            min-height: 180px;
            max-width: 90%;
        }
    }

    &__submit {
        margin-top: 40px;
        background: black;
        padding: 12px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
            background: lighten($main-color, 20%);
        }
    }

}

.default-contact-box {
    width: 65%;
    height: 75px;
    margin-bottom: 30px;
    @include vertical-flex;

}

.default-contact-input {
    height: 10px;
    max-width: 400px;
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

}