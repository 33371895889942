* {
    margin: 0;
    padding: 0;
    color: white;
    text-decoration: none;
    list-style: none;
}

body {
    min-width: 320px;
}

//?-------------------Breakpoints---------------------

$small-screen: 780px;

@mixin small-screen {
    @media (max-width: $small-screen) {
        @content;
    }
}

//?--------------------------Colors-------------------------

$main-color: black;

//?-------------------------Font-Config--------------------
@font-face {
    font-family: "title-font";
    src: url(../../public/fonts/title-regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "element-font";
    src: url(../../public/fonts/element-light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "quicksand";
    src: url(../../public/fonts/Quicksand-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}


$title-font: 'title-font', sans-serif;
$element-font: 'element-font', sans-serif;
$text-font: 'quicksand', sans-serif;

//?----------------------Mixins---------------------
@mixin vertical-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

//?----------------------Default-Style---------------


.default-text-style {
    color: $main-color;
    font-family: $text-font, Arial, Helvetica, sans-serif;
    font-size: 23px;
    width: 80%;
    text-align: center;
    padding: 16px;
    border-radius: 20px;

    @include small-screen {
        font-size: 18px;
    }
}

.bold {
    font-weight: bold;
}

strong {
    font-weight: inherit;
    color: inherit;
}

button{
    background: none;
    border: none;
}