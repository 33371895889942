.opening-hours-main {
  @include vertical-flex;
  animation: fade-in-down 0.4s ease-in forwards;
}

.hours-table {
  @include vertical-flex;
  padding-top: 200px;
  width: 70%;
  max-width: 900px;
  align-self: center;

  @include small-screen {
    width: 90%;
  }

  &__title {
    margin-bottom: 20px;
    color: $main-color;
    font-size: 56px;
    font-family: $title-font;

    @include small-screen {
      font-size: 26px;
    }

  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffff;
    border: 6px solid black;

    @include small-screen {}
  }

}




.opening-hours__months-container {
  min-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 14px;
}

.month-button {
  padding: 4px;
}

th,
td {
  border: 1px solid $main-color;
  padding: 8px;
  color: $main-color;
  text-align: center;
  font-size: 26px;

  @include small-screen {
    font-size: 18px;
  }
}

th {
  background-color: #000000;
  color: white;
}

tr:nth-child(even) {
  background-color: #4135356b;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}


.month-selector {
  margin-bottom: 10px;

  &__label {
    color: #000000;
    padding-right: 10px;
    font-size: 30px;
    font-family: $title-font;

    @include small-screen {
      font-size: 24px;
    }
  }
}

.selected-month,
.month-option {
  color: $main-color;
  text-transform: capitalize;
  font-family: $title-font;

  @include small-screen {
    font-size: 18px;
  }
}

.month-option {
  background-color: #000000;
  color: white;

}

.selected-month {
  color: white;
  background: #000000;
  cursor: pointer;
  font-size: 20px;
  padding: 6px;

  @include small-screen {
    width: 110px;
    font-size: 14px;
  }
}

.opening-day {
  text-transform: capitalize;
}