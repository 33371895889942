.lore-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;
    padding-top: 200px;
    animation: fade-in-down 0.4s ease-in forwards;

    &__title {
        color: $main-color;
        font-family: title-font, Arial, Helvetica, sans-serif;
        margin-bottom: 80px;
        font-size: 56px;
        text-align: center;
        padding-bottom: 60px;

        @include small-screen {
            font-size: 24px;
            padding: 0 8px;
        }
    }

    &__text {
        margin-top: -50px;
        margin-bottom: 80px;
        padding-bottom: 40px;
        border-bottom: 2px solid black;
        border-radius: 0;
        max-width: 1100px;

        @include small-screen {}
    }
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: space-around;
}

.card {
    width: 300px;
    height: 480px;
    margin-bottom: 20px;
    border: 2px solid black;
    border-radius: 6px;
    text-align: center;
    margin-inline-end: 20px;
    margin-inline-start: 20px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 0px 0px black;
    transition: all 0.15s ease-in-out;
    position: relative;
    cursor: pointer;
    z-index: 0;
    overflow: hidden;

    @include small-screen {
        width: 280px;
        height: 460px;
    }

    &__img {
        width: 100%;
        height: 88%;
    }

    &__title {
        color: $main-color;
        font-family: $element-font;
        font-size: 20px;

        @include small-screen {
            font-size: 18px;
        }
    }

    &__description {
        font-family: $text-font;
        color: $main-color;
        margin-bottom: 2px;
        font-size: 16px;

        @include small-screen {
            font-size: 15px;
        }
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 18px 18px 4px 0px black;
    }
}



//* Modal img *//



.modal {
    animation: fade-in-down 0.4s ease-in-out forwards;
    display: block;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 110%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);

    &__content {
        margin: 15% auto;
        margin-top: 800px;
        padding: 20px;
        max-width: 500px;
        background-color: #fefefe;
        position: relative;
        text-align: center;

        @include small-screen {
            margin-top: 800px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
    }

    &__address {
        color: black;
        font-size: 20px;
    }
}

.close {
    margin-top: -10px;
    color: black;
    float: right;
    font-size: 50px;
    font-weight: bold;
    cursor: pointer;
}