.header {
    background: $main-color;
    width: 100%;
    color: white;
    position: fixed;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__line {
        border-bottom: 1px solid white;
        width: 100%;
    }

    &__title {
        text-align: center;
        font-family: title-font, Arial, Helvetica, sans-serif;
        padding-top: 38px;
        font-weight: 400;
        transition: all 0.5s ease-in-out;
        width: auto;

        @include small-screen {
            font-size: 26px;
            padding-top: 28px;
        }
    }

    &__nav {
        width: 100%;
    }

    &__nav-list {
        padding-top: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 560px;
        margin: auto;

        @include small-screen {
            padding-top: 8px;
            width: 100%;
        }

        &__element {
            font-family: element-font, Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-size: 28px;
            transition: all 0.3s ease-in-out;
            border-bottom: 1px solid rgba(255, 255, 255, 0);

            @include small-screen {
                font-size: 16px;
            }

            &:hover {
                border-bottom: 1px solid white;
            }
        }
    }
}


//?------------Scrolling-------------//
.maxi-header {
    height: 162px;

    @include small-screen {
        height: 112px;
    }
}

.mini-header {
    height: 102px;

    @include small-screen {
        height: 70px;
    }

}

.maxi-title {
    font-size: 42px;

    @include small-screen {
        font-size: 28px;
    }

}

.mini-title {
    font-size: 28px;
    margin-top: -24px;

    @include small-screen {
        font-size: 20px;
    }

}

.mini-element {
    font-size: 22px;

    @include small-screen {
        font-size: 18px;
    }
}

.nav-active {
    border-bottom: 2px solid white;
    font-weight: bold;
}


//? STICKER ?//


.sticker {
    position: fixed;
    top: 14px;
    right: 20px;
    border: 2px solid white;
    border-radius: 12px 0px 12px 0px;
    color: black;
    padding: 5px 10px;
    font-weight: bold;
    background: white;
    transition: all 0.2s ease-in-out;

    @include small-screen {
        font-size: 12px;
        top: 120px;
        right: -4px;
        color: black;
        border: 2px solid black;
        border-radius: 6px 0px 0px 6px;
        background: white;
        z-index: -1;
        transition: margin-right 0.2s ease-in-out, background 0.1s ease-in-out;
    }

}

.hide-sticker {
    @include small-screen {
        font-size: 0;
        margin-right: -110px;
        background: black;
        border: 2px solid white;
        width: 108px;
        height: 20px;
        overflow: hidden;

    }
}

//?--------- LANGUAGE SELECTOR --------//

.selected-language {
    position: absolute;
    top: 14px;
    left: 80px;
    font-size: 12px;
    color: black;
    width: 50px;
    border-radius: 12px 0px;
    text-align: center;
    font-weight: 900;
    height: 40px;
    transition: all 0.2s ease-in-out;

    @include small-screen {
        width: 40px;
        height: 30px;
        left: 10px;
        top: 6px;
    }
}


.flag {
    width: 40px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    @include small-screen {
        width: 30px;
    }

    &:hover {
        transform: scale(1.05);
    }

}

.language-list {
    background-color: black;
    border: 2px solid white;
    border-radius: 0px 0px 12px 12px;
    border-top: 0;
    margin-left: -6px;
    transition: all 0.3s cubic-bezier(.56, 1.64, .77, .91);
    width: 50px;

    @include small-screen {
        width: 40px;
    }
}

.language-button {
    display: flex;
    justify-content: space-between;
    width: 60px;
    align-items: center;
    cursor: pointer;

    @include small-screen {
        width: 40px;
    }
}

.language-chevron {
    width: 10px;
    transform: rotate(270deg);
    transition: all 0.1s ease-in-out;

    @include small-screen {
        width: 5px;
    }
}

.chevron-up {
    transform: rotate(90deg);
}

.hidden-list {
    margin-top: -340px;
}

.selected-flag {
    opacity: 0.2;
    border-bottom: 2px solid white;

}

.hidden-selected {
    margin-top: -200px;
}