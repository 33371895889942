.notfound {
    min-height: 400px;
    padding-top: 300px;
    padding-bottom: 240px;
    animation: fade-in-down 0.4s ease-in forwards;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & a {
            border-bottom: 2px solid black;
        }
    }

    &__404 {
        color: $main-color;
        font-family: $title-font;
        font-size: 120px;

        @include small-screen {
            font-size: 80px;
        }
    }

    &__title {
        font-size: 36px;
        color: $main-color;
        font-family: $title-font;
        text-align: center;
        padding-right: 20px;
        padding-left: 20px;

        @include small-screen {
            font-size: 26px;
        }
    }

    &__text {
        text-align: center;
        margin: auto;

    }

    &__escape-door {
        color: $main-color;
        background: black;
        padding: 4px;
        border-radius: 12px 12px 0px 0px;
        width: 80px;
        transition: all 0.2s ease-out;
        border-bottom: 2px solid black;

        @include small-screen {
            width: 60px;
        }

        &:hover {
            transform: scale(1.05);
        }
    }

}