.home {
    display: flex;
    flex-direction: column;

    &-main {
        animation: fade-in-down 0.4s ease-in forwards;

        &__title {
            color: $main-color;
            font-family: title-font, Arial, Helvetica, sans-serif;
            margin-bottom: 80px;
            margin-top: 40px;
            font-size: 42px;
            text-align: center;
            padding: 2px 8px;

            @include small-screen {
                font-size: 26px;
            }
        }

        &__text {
            max-width: 1100px;
            border-top: 2px solid black;
            border-radius: 0;
            margin-top: 80px;
            padding-top: 80px;
        }
    }
}

.areweopen {
    padding: 20px;
    text-align: center;
    font-family: element-font, Arial, Helvetica, sans-serif;
    transition: all 0.2s ease-in;
    border-radius: 12px 12px 12px 12px;
    color: black;
    border-top: 2px solid black;
    border-bottom: 1px solid black;
    margin-bottom: 60px;

    @include small-screen {
        border-radius: 0;
        border: 0;
        padding: 20px 0px;
        background: black;
        color: white;
        width: 100%;
        margin-bottom: 0;
    }

    &__regular-text {
        font-size: 20px;
        color: $main-color;
        font-family: $title-font ;

        @include small-screen {
            color: white;
        }
    }
}

.hiding {
    transform: translateY(-180%);
    margin-bottom: -60px;

    @include small-screen {
        transform: translateY(0%);
        margin-bottom: 0;
    }
}


.open,
.closed {
    color: $main-color;
    font-size: 42px;
    font-family: $title-font;
    font-weight: bold;

    @include small-screen {
        color: white;
    }
}

.author {
    color: $main-color;
    font-size: 12px;
    margin-top: 20px;
}